<template>
  <validation-provider
    :rules="rules"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
  >
    <div
      :class="{
        'hm-form__element-wrapper': true,
        'hm-form__element-wrapper--error': v.errors.length
      }"
    >
      <div class="hm-form__element-label">
        {{ name }}
        <span v-if="required">*</span>
      </div>
      <network
        v-model="localValue"
        :items="netMasks"
        @change="onInput"
      />
      <div v-if="v.errors.length || hintText" class="hm-form__element-hint">
        {{ v.errors.length ? v.errors[0] : hintText }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import uid from '@/components/hm-form/mixins/uid';
import Network from './Network.vue';

export default {
  name: 'HmNetwork',
  mixins: [uid],
  components: { Network },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    netMasks: {
      type: Array,
      default: () => [], // маски подсетей
    },
    name: {
      type: String,
      default: undefined,
    },
    hintText: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
  },
};
</script>
